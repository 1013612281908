import React from 'react';
import { Link, Button } from 'gatsby-material-ui-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

interface PersonaCardProps {
  slug: string;
  title: string;
  description: string;
  image: IGatsbyImageData;
}

export default (props: PersonaCardProps) => (
  <Card variant='outlined'>
    <GatsbyImage alt={props.title} image={props.image} />
    <CardContent sx={{ padding: 3 }}>
      <Box position='relative'>
        <Link to={props.slug}>
          <Typography variant='h6' component={'h2'} textTransform='uppercase' gutterBottom>
            {props.title}
          </Typography>
        </Link>
        <Box
          position='absolute'
          top={0}
          bottom={0}
          ml={-3} // == -1 * padding CardContent
          width={7}
          bgcolor='divider'
        />
      </Box>
      <Typography variant='body2'>{props.description}</Typography>
    </CardContent>
    <CardActions sx={{ padding: 3, paddingTop: 0, justifyContent: 'space-around' }}>
      <Button to={props.slug} variant='contained' sx={{ minWidth: '60%' }}>
        Więcej
      </Button>
    </CardActions>
  </Card>
);
