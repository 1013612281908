import React from 'react';
import { Breadcrumbs, TypographyProps } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';

interface StandardBreadcrumbsProps {
  path: { title: string; link: string }[];
  mt?: TypographyProps['mt'];
}

export default (props: StandardBreadcrumbsProps) => {
  const { path, ...other } = props;
  return (
    <Breadcrumbs
      {...other}
      // TS zgłasza problem z propsami przekazywanymi do Typography, ale wg źródeł to jest poprawne:
      // https://github.com/mui/material-ui/blob/81b0ac94705d4df20c5e7bf17d8fcb704bcb04eb/packages/mui-material/src/Breadcrumbs/Breadcrumbs.js#L173
      variant='caption'
      color='gray'
      textTransform='uppercase'
      separator='|'
    >
      <Link fontSize='inherit' color='inherit' underline='none' to='/'>
        Mediporta
      </Link>
      {path.map((item, index, { length }) =>
        index < length - 1 ? (
          <Link key={index} fontSize='inherit' color='inherit' underline='none' to={item.link}>
            {item.title}
          </Link>
        ) : (
          <Link key={index} fontSize='inherit' color='text.secondary' underline='none' to={item.link}>
            {item.title}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
};
