import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Enumerable from 'linq';
import slugify from 'slugify';

import { Box, Grid, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import StandardFrame from '../components/StandardFrame';
import PersonaCard from '../components/Cards/PersonaCard';
import StandardBreadcrumbs from '../components/StandardBreadcrumbs';
import AppBarOffsetedScrollContainer from '../components/AppBarOffsetedScrollContainer';
import Hero from '../components/Heros/Hero';
import HeroTitle from '../components/Heros/HeroTitle';
import { ContactForm } from '../components/Sections/ContactForm';

export const pageQuery = graphql`
  query PersonaIndex {
    persona: allMarkdownRemark(
      filter: { childPersona: { id: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              order
              title
              description
              card_image {
                childImageSharp {
                  gatsbyImageData(width: 380, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
          }
        }
      }
    }
  }
`;

const slugifyGroup = (fieldValue: string) => {
  return slugify(fieldValue, { lower: true });
};

export default ({
  data: {
    persona: { group }
  },
  location,
  navigate
}: PageProps<Queries.FunctionIndexQuery>) => {
  group = Enumerable.from(group)
    .orderBy(({ edges }) => Enumerable.from(edges).min(({ node }) => node.frontmatter!.order || 0))
    .toArray();

  const [value, setValue] = React.useState(
    location.hash.startsWith('#') ? location.hash.substring(1) : slugifyGroup(group[0].fieldValue!)
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate('#' + newValue);
  };

  // Musimy wymusić rerender, gdyż tabsy nie pokazują się opdowiednio po bezpośrednim wejściu na stronę z hashem
  useEffect(() => {
    const value = location.hash.startsWith('#') ? location.hash.substring(1) : slugifyGroup(group[0].fieldValue!);

    setValue(value);
  }, [group, location.hash]);

  return (
    <>
      <Helmet title='Demo' />
      <StandardFrame>
        <Hero
          image={
            <StaticImage
              style={{ height: '100%' }}
              layout='fullWidth'
              quality={95}
              src='../images/hero_convertible.jpg'
              alt=''
            />
          }
        >
          <HeroTitle>
            Wybierz swoją specjalizację
            <br />i wypróbuj wersję demo
          </HeroTitle>
          <Box>
            <Typography color='white' gutterBottom>
              Stworzyliśmy i rozwijamy program korzystając z merytorycznego wsparcia środowiska medycznego wielu
              specjalizacji. Stawiamy na prostotę obsługi oraz stałą aktualizację zgodności z przepisami.
            </Typography>
            <Typography color='white' gutterBottom>
              Aby skrócić czas pracy z dokumentacją medyczną on-line skonfigurujemy program tak, by w kompaktowym oknie
              wizyty widoczne były tylko niezbędne komponenty.
            </Typography>
            <Typography color='white' gutterBottom>
              Wybierz swoją specjalizację i przetestuj bezpłatną wersję demo!
            </Typography>
          </Box>
        </Hero>
        <AppBarOffsetedScrollContainer maxWidth='lg'>
          <StandardBreadcrumbs path={[{ title: 'Demo', link: '/demo' }]} />
          <Box mt={2}>
            <TabContext value={value}>
              <TabList value={value} onChange={handleChange} variant='fullWidth'>
                {group.map(({ fieldValue }, index) => (
                  <Tab key={index} value={slugifyGroup(fieldValue!)} label={fieldValue} />
                ))}
              </TabList>
              {group.map(({ fieldValue, edges }, index) => (
                <TabPanel key={index} value={slugifyGroup(fieldValue!)}>
                  <Grid container spacing={3} mt={-3}>
                    {edges.map(({ node }) => (
                      <Grid key={node.id} item xs={12} sm={6} md={4}>
                        <PersonaCard
                          slug={node.fields!.slug}
                          title={node.frontmatter!.title!}
                          description={node.frontmatter!.description!}
                          image={node.frontmatter!.card_image!.childImageSharp!.gatsbyImageData!}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </AppBarOffsetedScrollContainer>
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
