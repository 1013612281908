import React from 'react';
import { Typography } from '@mui/material';

export default (props: React.PropsWithChildren<unknown>) => (
  <Typography
    variant='h2'
    component='h1'
    color='white'
    textTransform='uppercase'
    sx={{ ':first-line': { fontSize: '150%', fontWeight: 700 } }}
    gutterBottom
  >
    {props.children}
  </Typography>
);
