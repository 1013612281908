import React from 'react';
import { Box, BoxProps } from '@mui/material';
import PageSectionContent, { PageSectionContentProps } from './PageSectionContent';

interface PageSectionWithImageProps {
  bgcolor?: BoxProps['bgcolor'];
  color?: BoxProps['color'];
  sx?: BoxProps['sx'];
  bgimage: React.ReactNode;
  minHeight?: BoxProps['minHeight'];
  maxWidth?: PageSectionContentProps['maxWidth'];
  py?: PageSectionContentProps['py'];
}

export default (props: React.PropsWithChildren<PageSectionWithImageProps>) => (
  <Box
    component='section'
    color={props.color}
    sx={props.sx}
    // Punkt odniesienia dla elementu z position: absolute (tło i strzałka do przewijania).
    position='relative'
    overflow='hidden'
    minHeight={props.minHeight}
  >
    <Box
      // https://stackoverflow.com/questions/31267425/use-the-html-img-tag-as-a-background-image-instead-of-the-css-background-image
      position='absolute'
      width='100%'
      height='100%'
      zIndex={-1}
      bgcolor={props.bgcolor}
    >
      {props.bgimage}
    </Box>
    <PageSectionContent maxWidth={props.maxWidth} py={props.py}>
      {props.children}
    </PageSectionContent>
  </Box>
);
