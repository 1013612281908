import { Typography } from '@mui/material';
import React from 'react';

export default (props: React.PropsWithChildren<unknown>) => (
  <Typography
    variant='h3'
    component='h2'
    textTransform='uppercase'
    sx={{ ':first-line': { fontSize: '150%', fontWeight: 700 } }}
    mb={3}
  >
    {props.children}
  </Typography>
);
